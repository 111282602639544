:root {
  --app-text-color: #212529;
  --app-bg-color: #ff5988;
  --app-border-color: #ff5988;
}

.app-border-color {
  border-color: var(--app-border-color) !important;
}

.app-bg-color {
  background-color: var(--app-bg-color);
}

.app-btn {
  background-color: var(--app-bg-color) !important;
  border: 1px solid var(--app-border-color) !important;
  color: #fff !important;
}

.app-btn-outline {
  background-color: #fff !important;
  border: 1px solid var(--app-border-color) !important;
  color: #000 !important;
}

body {
  z-index: 0;
  background: transparent;
}

a {
  text-decoration: none !important;
}

.container-full-bg::before {
  content: '';
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/svg/trongdong.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.05;
  z-index: -1;
  pointer-events: none;
}

.footer {
  /*height: 90px;*/
  bottom: -1px;
  left: 0;
  position: absolute;
  right: 0;
}

.marquee-container {
  width: 100%; /* Chiều rộng của vùng nhìn thấy */
  overflow: hidden; /* Ẩn phần chữ ra khỏi container */
  white-space: nowrap; /* Chữ nằm trên một dòng */
}

.marquee {
  display: flex;
}

.marquee > h2 {
  padding-right: 70px;
}

/* TABLE  */
.table {
  margin-bottom: 0 !important;
}
table th {
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
}

/* ACCORDION */
.accordion-button:focus {
  box-shadow: unset !important;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  /* --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; */
  /* --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius); */
  --bs-accordion-border-color: #ff5988 !important;
  --bs-accordion-border-width: 0px !important;
  --bs-accordion-border-radius: var(--bs-border-radius);

  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  /* --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg); */

  --bs-accordion-btn-color: #212529 !important;
  --bs-accordion-btn-bg: #f0f2f3 !important;

  /* --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e); */
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  /* --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23052c65%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e); */
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #212529 !important;
  --bs-accordion-active-bg: #f0f2f3 !important;
}

.accordion-button:not(.collapsed) {
  /* color: #000; */
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dbd9d9 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
